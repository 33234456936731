import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#D97014",
    },
    //@ts-ignore
    gray: {
      main: "#B7BDC7",
      contrastText: "#000",
    },
    error: {
      main: "#732C02",
    },
  },
});

export default theme;
