import "../styles/globals.css";
import type { AppProps } from "next/app";
import Footer from "../components/footer";
import Head from "next/head";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import theme from "../global/theme";
import createEmotionCache from "../global/cache";
import Nav from "../components/nav";
import SearchProvider from "../contexts/search";
import { SessionProvider } from "next-auth/react";
import UserProvider from "../contexts/user";
import RequireAuth from "../components/require-auth";
import Script from "next/script";

const clientSideEmotionCache = createEmotionCache();

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <>
      <CacheProvider value={clientSideEmotionCache}>
        <ThemeProvider theme={theme}>
          <SessionProvider session={session} refetchOnWindowFocus={false}>
            <SearchProvider>
              <Head>
                <meta
                  name="viewport"
                  content="initial-scale=1, width=device-width"
                />
                <title>TOPPICK</title>
              </Head>
              <CssBaseline />
              <UserProvider>
                <Nav>
                  <div
                    className="pageWrapper"
                    style={{ minHeight: "calc(100% - 60px)" }}
                  >
                    {
                      //@ts-ignore
                      Component.authRequired ? (
                        <RequireAuth>
                          <Component {...pageProps} />
                        </RequireAuth>
                      ) : (
                        <Component {...pageProps} />
                      )
                    }
                  </div>
                  <Footer />
                </Nav>
              </UserProvider>
            </SearchProvider>
          </SessionProvider>
        </ThemeProvider>
      </CacheProvider>
      <Script
        id="tag"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-XWYNLRKEZG`}
      />
      <Script
        id="ga"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
             window.dataLayer = window.dataLayer || [];
             function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
             gtag('config', 'G-XWYNLRKEZG', {
               page_path: window.location.pathname,
             });
           `,
        }}
      />
    </>
  );
}

export default MyApp;
