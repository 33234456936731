import { useEffect, useState, useRef } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "next/link";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";
import ExploreTwoToneIcon from "@mui/icons-material/ExploreTwoTone";
import StarRateTwoToneIcon from "@mui/icons-material/StarRateTwoTone";
import CreateTwoToneIcon from "@mui/icons-material/CreateTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import FormatListNumberedTwoToneIcon from "@mui/icons-material/FormatListNumberedTwoTone";
import { LoginOutlined, LogoutTwoTone } from "@mui/icons-material";
import { useSession } from "next-auth/react";
import { signOut } from "next-auth/react";
import Tooltip from "@mui/material/Tooltip";
import Header from "../components/header";
import CircularProgress from "@mui/material/CircularProgress";
import Router from "next/router";

export default function MiniDrawer({ children }: { children: any }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { status } = useSession();
  const authed = status === "authenticated";
  const [loading, setLoading] = useState(false);
  const layoutRef = useRef(null);

  useEffect(() => {
    Router.events.on("routeChangeStart", () => {
      setLoading(true);
    });
    Router.events.on("routeChangeComplete", () => {
      if (layoutRef.current) {
        //@ts-ignore
        layoutRef.current.scrollTop = 0;
      }
      setLoading(false);
    });
    Router.events.on("routeChangeError", () => {
      setLoading(false);
    });
  }, []);

  const handleDrawerOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ height: "100%", display: "flex", overflow: "hidden" }}>
      <div
        className={`nav ${open ? "open" : ""}`}
        style={{ width: open ? 240 : 64, minWidth: open ? 240 : 64 }}
        onClick={() => {
          handleDrawerClose();
        }}
      >
        <div className="menuButton">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </div>

        <div className="innerMenu">
          {!open ? null : (
            <div
              className="menuHeader"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Link href="/" style={{ cursor: "pointer" }}>
                <span style={{ letterSpacing: 1.5 }}>TOPPICK</span>
              </Link>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon fontSize={"medium"} />
                ) : (
                  <ChevronLeftIcon fontSize="large" sx={{ color: "white" }} />
                )}
              </IconButton>
            </div>
          )}
          {/* {['Explore', 'Trending', 'Featured'].map((text, index) => ( */}

          <List>
            <Link href="/explore">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="Explore Toppicks" placement="bottom-start">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: "white",
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ExploreTwoToneIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"Explore"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link href="/trending">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="Trending Toppicks" placement="bottom-start">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: "white",
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <TrendingUpTwoToneIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"Trending"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link href="/featured">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="Featured Toppicks" placement="bottom-start">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: "white",
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <StarRateTwoToneIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"Featured"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link href="/make-picks">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="Make Picks" placement="bottom-start">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: "white",
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <CreateTwoToneIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"Make Picks"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <Link href="/profile">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="My Profile" placement="bottom-start">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: "white",
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AccountCircleTwoToneIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"My Profile"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link href="/my-picks">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title="My Picks" placement="bottom-start">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: "white",
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <FormatListNumberedTwoToneIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"My Picks"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <Link href="/favorites">
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                                <Tooltip title="Favorite Toppicks" placement="bottom-start">

                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      color: "white",
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FavoriteTwoToneIcon />
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"My Favorites"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link> */}
            <Divider />
            {!authed ? (
              <Link href="/auth/signin">
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title="Log In / Sign Up" placement="bottom-start">
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          color: "white",
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <LoginOutlined />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={"Log in / Sign up"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ) : (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    signOut({ callbackUrl: "/" });
                  }}
                >
                  <Tooltip title="Log Out" placement="bottom-start">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: "white",
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <LogoutTwoTone />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={"Log out"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </div>
      </div>
      <div
        id="layout"
        style={{ flex: 1, height: "100%", overflowY: "auto" }}
        ref={layoutRef}
      >
        <div className="header">
          <Header toggleMenu={setOpen} />
        </div>
        {children}
        {loading ? (
          <div className="overlay">
            <CircularProgress
              color="secondary"
              size={100}
              style={{ marginLeft: 60 }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
