import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import CircularProgress from "@mui/material/CircularProgress";

const RequireAuth = ({ children }: { children: any }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  if (status === "loading") {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#35496950",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          color="secondary"
          size={100}
          style={{ marginLeft: 60 }}
        />
      </div>
    );
  } else if (status === "authenticated") {
    return <>{children}</>;
  } else {
    router.push("/auth/signin");
  }
  return null;
};

RequireAuth.authRequired = true;
export default RequireAuth;
