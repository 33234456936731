import Search from "./search";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { SearchContext } from "../contexts/search";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

const Header = ({ toggleMenu }: { toggleMenu: any }) => {
  const router = useRouter();
  const { vals, updateContext } = useContext(SearchContext);

  const handleSearchChange = (searchVals: any) => {
    if (searchVals.newOption) {
      //go to make picks
      updateContext((prev: any) => ({
        ...prev,
        createdOptions: {
          ...prev.createdOptions,
          [searchVals.value]: searchVals.label,
        },
      }));
      router.push(
        `/make-picks?toppick=${searchVals.value}&toppick_description=${searchVals.label}`
      );
    } else {
      const [toppickId, locationId] = searchVals.value.split("___");
      router.push(`/picks/${toppickId}/${locationId}`);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        paddingRight: 24,
        paddingLeft: 12,
      }}
    >
      <div className="mobileHomeLink">
        <IconButton
          onClick={() => {
            toggleMenu((prev: boolean) => !prev);
          }}
        >
          <MenuIcon fontSize="large" style={{ color: "white" }} />
        </IconButton>
      </div>
      {/* <div className="mobileHomeLink"><Link href="/"><><Image height={34} width={34} src={whiteT}/></></Link></div> */}
      <div
        style={{
          paddingLeft: 12,
          fontSize: 24,
          fontWeight: "bold",
          letterSpacing: 1,
        }}
        className="desktopHomeLink gradient-text"
      >
        <Link href="/" className="gradient-text">
          TOPPICK
        </Link>
      </div>
      <Search
        minWidth={240}
        placeholder="Search Toppicks"
        onChange={handleSearchChange}
        resetMode={true}
      />
    </div>
  );
};

export default Header;
