import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSession } from "next-auth/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Link from "next/link";
import * as Sentry from "@sentry/nextjs";

export const initialState = {
  id: "",
  name: "",
  presented_tags: false,
};

const UserContexts = React.createContext({
  user: initialState,
  updateContext: (val: any) => {
    //do nothing
  },
});

const UserProvider = ({ children }: { children: any }) => {
  const [state, setState] = useState(initialState);
  const consumerObject: any = { user: state, updateContext: setState };

  const { data: session, status }: { data: any; status: string } = useSession();

  const setTagsPresented = () => {
    axios.put(`/v1/user`, { presented_tags: true });
    setState((prev) => ({ ...prev, presented_tags: true }));
  };

  useEffect(() => {
    if (status === "authenticated") {
      axios({
        url: `/v1/user`,
      }).then(({ data }: { data: any }) => {
        setState({ ...data, id: data.id, name: data.name });
        Sentry.setUser({ email: data.email, id: data.id });
      });
    }
  }, [status]);

  return (
    <UserContexts.Provider value={consumerObject}>
      {children}
      <Dialog
        open={!!(state.id && !state.presented_tags)}
        onClose={() => {
          setTagsPresented();
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Take your picks to the next level"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Congrats joining TOPPICK! To realize the full
            power of TOPPICK, head over to your profile and add some tags to
            your profile. Every tag you add allows your picks to be aggregated
            with everyone else sharing those tags. It's what makes TOPPICK
            magical. It only takes a second, we promise to keep your data safe
            and never use it to advertise to you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setTagsPresented();
            }}
          >
            Not Right Now
          </Button>
          <Link href="/profile">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setTagsPresented();
              }}
            >
              Yes, please!
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </UserContexts.Provider>
  );
};

export const UserContext = UserContexts;

export default UserProvider;
