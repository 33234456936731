import React, { useState } from "react";

export const initialState = {
  createdOptions: {},
};

const SearchContexts = React.createContext({
  vals: initialState,
  updateContext: (val: any) => {
    //do nothing
  },
});

const SearchProvider = ({ children }: { children: any }) => {
  const [state, setValue] = useState(initialState);
  const consumerObject: any = { vals: state, updateContext: setValue };
  return (
    <SearchContexts.Provider value={consumerObject}>
      {children}
    </SearchContexts.Provider>
  );
};

export const SearchContext = SearchContexts;

export default SearchProvider;
