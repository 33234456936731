import React, { useState, useEffect, useCallback } from "react";

import AsyncCreatableSelect from "react-select/async-creatable";
import axios from "axios";
import Button from "@mui/material/Button";

// TODO: move this somewhere else.
function debouncePromise<T extends (...args: any[]) => any>(
  fn: T,
  wait: number,
  abortValue: any = undefined
) {
  let cancel = () => {};
  // type Awaited<T> = T extends PromiseLike<infer U> ? U : T
  type ReturnT = Awaited<ReturnType<T>>;
  const wrapFunc = (...args: Parameters<T>): Promise<ReturnT> => {
    cancel();
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => resolve(fn(...args)), wait);
      cancel = () => {
        clearTimeout(timer);
        if (abortValue !== undefined) {
          reject(abortValue);
        }
      };
    });
  };
  return wrapFunc;
}

interface State {
  readonly inputValue: string;
}

const Search = ({
  placeholder,
  initialValue,
  initialOptions,
  minWidth,
  onChange,
  menuRight, // TODO: remove
  prefix, // pass this in when during a location search.
  keyPartOnly, // pass this in to remove options that include location. -- eventually we'll have to remove location keys from this search.
  createPicksMessage,
  persistentOptions,
  disabled,
  resetMode,
  style = {},
}: {
  placeholder: string;
  initialValue?: { label: string; value: string };
  initialOptions?: any;
  minWidth?: number;
  onChange?: (value: { label: string; value: string }) => void;
  menuRight?: boolean;
  prefix?: string;
  keyPartOnly?: string;
  createPicksMessage?: boolean;
  persistentOptions?: [{ label: string; value: string }] | [];
  disabled?: boolean;
  resetMode?: boolean;
  style?: any;
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(initialOptions || []);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    // this stuff sucks. hire real devs to fix this shit.
    if (initialValue && !initialValue.label) {
      const option = options.find(
        (opt: any) => opt.value == initialValue.value
      );
      setSelectedValue(option);
    } else {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  // TODO: don't make requests after none found.
  const performSearch = (inVal: string) => {
    inVal = inVal.replace(/\W/g, "_");
    if (prefix) {
      inVal = prefix + "___" + inVal;
    }
    return axios.get(`/v1/search?search=${inVal}`).then(({ data }) => {
      const filteredPerOptions =
        persistentOptions?.filter(
          (po) => po.value.includes(inputValue) && po.label
        ) || [];
      const persistentOptionsMap = filteredPerOptions.reduce((memo, item) => {
        //@ts-ignore
        memo[item.value] = true;
        return memo;
      }, {});
      let transformedResponse = data.data.reduce(
        (memo: any, item: any) => {
          const keyPart = keyPartOnly && keyPartOnly === "pick" ? 0 : 1;
          const parts = item.name.split(":::");
          const key = keyPartOnly ? parts[0].split("___")[keyPart] : parts[0];
          const label = keyPartOnly
            ? parts[1].split(" in ")[keyPart]
            : parts[1]; // this is a total hack. Probably need to prefix search keys with something like picks__::key, and full_key__::key, and then search on the prefix insteads.
          if (!memo.picks[key]) {
            memo.picks[key] = true;
            memo.options.push({ label: label, value: key });
          }
          return memo;
        },
        { picks: persistentOptionsMap || {}, options: filteredPerOptions || [] }
      );
      return transformedResponse.options;
    });
  };

  const debouncedSearch = useCallback(
    debouncePromise(performSearch, 500, "Aborted"),
    []
  );

  const handleChange = (value: any, newValue: any) => {
    if (value.label.includes(" (new)")) {
      value.label = value.label.replace(" (new)", "");
      value.newOption = true;
    }
    onChange && onChange(value);
    if (!resetMode) {
      setSelectedValue(value);
    }
  };

  useEffect(() => {
    if (!prefix) {
      performSearch("").then((data) => {
        setOptions(data);
        if (selectedValue && !selectedValue.label) {
          const option = data.find(
            (opt: any) => opt.value == selectedValue.value
          );
          setSelectedValue(option || null);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (prefix) {
      performSearch("").then((data) => {
        setOptions(data);
        if (selectedValue && !selectedValue.label) {
          const option = data.find(
            (opt: any) => opt.value == selectedValue.value
          );
          setSelectedValue(option || null);
        }
      });
    }
  }, [prefix]);

  return (
    <AsyncCreatableSelect
      placeholder={placeholder}
      instanceId="nav-search"
      cacheOptions
      loadOptions={debouncedSearch}
      isDisabled={disabled}
      tabSelectsValue={true}
      // menuIsOpen={true}
      onChange={handleChange}
      createOptionPosition="first"
      getNewOptionData={(val) => {
        return {
          label: `${val} (new)`,
          value: val.toLowerCase().replace(/\W/g, "_"),
        };
      }}
      defaultValue={selectedValue}
      value={selectedValue?.value ? selectedValue : null}
      defaultOptions={options}
      noOptionsMessage={() => {
        return createPicksMessage ? (
          <>No matching picks... yet.</>
        ) : (
          "No Options"
        );
      }}
      styles={{
        container: (provided, state) => ({
          ...provided,
          ...style,
          minWidth: minWidth ? minWidth : "initial",
        }),
        control: (provided, state) => ({
          ...provided,
          backgroundColor: "transparent",
          borderImageSource: "linear-gradient(#ff5f6d, #ffc371)",
          borderWidth: 1,
          borderImageSlice: 1,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          color: "white",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "white",
        }),
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: "#1C2A3F",
          // width: 'initial',
          right: menuRight ? "0px" : "unset",
        }),
        //@ts-ignore
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? "#354969" : null,
          fontSize: 14,
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }),
      }}
    />
  );
};

export default Search;
