import Link from "next/link";
import styles from "../styles/footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <div>
          <div className={styles.sectionHeader}>Platform</div>
          <div className={styles.footerLink}>
            <Link href="/how-toppick-works">How TOPPICK Works</Link>
          </div>
          <div className={styles.footerLink}>
            <Link href="/cash-money">How We Make Money</Link>
          </div>
          <div className={styles.footerLink}>
            <Link href="/privacy">Privacy Policy</Link>
          </div>
          <div className={styles.footerLink}>
            <Link href="/terms">Terms of Service</Link>
          </div>
        </div>
        <div>
          <div className={styles.sectionHeader}>Resources</div>
          <div className={styles.footerLink}>
            <Link href="/grow">Help TOPPICK Grow</Link>
          </div>
          <div className={styles.footerLink}>
            <Link href="/FAQs">F.A.Q.s</Link>
          </div>
          <div className={styles.footerLink}>
            <Link href="/advertise">Advertise With Us</Link>
          </div>
        </div>
        <div>
          <div className={styles.sectionHeader}>Company</div>
          <div className={styles.footerLink}>
            <Link href="/about">About Us</Link>
          </div>
          <div className={styles.footerLink}>
            <Link href="/contact">Contact Us</Link>
          </div>
          <div className={styles.footerLink}>
            <Link href="/careers">Careers</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
